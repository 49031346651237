import React from "react";
import { SwipeableDrawer, makeStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paperAnchorBottom": {
      borderRadius: "20px 20px 0 0",
    },
  },
  anchor: {
    height: "4px",
    width: "40px",
    marginTop: "10px",
    background: theme.color.offWhite,
    borderRadius: "4px",
  },
}));

/**
 * A customizable bottom anchored swipeable drawer
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.open whether to open drawer or not
 * @param {Function} root0.onOpen callback to handle drawer open
 * @param {Function} root0.onClose callback to handle drawer close
 * @param {object} root0.children child components
 *
 * @returns {React.ReactElement} - returns customizable bottom anchored swipeable drawer
 */
const BottomAnchoredDrawer = ({ open, onOpen, onClose, children }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      // disableBackdropTransition
      // disableDiscovery
      disableSwipeToOpen
      anchor="bottom"
      variant="temporary"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      className={classes.drawer}
    >
      <Grid container direction="row" justify="center">
        <span className={classes.anchor} />
      </Grid>
      {children}
    </SwipeableDrawer>
  );
};

BottomAnchoredDrawer.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

BottomAnchoredDrawer.defaultProps = {
  open: false,
  onOpen: () => {},
  onClose: () => {},
  children: <></>,
};

export default BottomAnchoredDrawer;
