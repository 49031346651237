import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import dynamic from "next/dynamic";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Image from "next/image";
import HTMLReactParser from "html-react-parser";
import BottomAnchoredDrawer from "../../../mobile/core/fnp-modal/bottom-anchor-drawer";
import { showMediaInfo } from "../../../../src/actions/common-page-actions";

const FNPCashData = dynamic(() => import("../../../common/fnpCashData"), { ssr: false });
const FNPCashText = dynamic(() => import("../../../common/fnpCashText"), { ssr: false });
const NewTextBadge = dynamic(() => import("../../desktop/header/dropdown/account-dropdown/newBadge"), {
  ssr: false,
});

const useStyles = makeStyles((theme) => ({
  list: {
    width: "80vw",
    height: "100%",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  listHeader: {
    backgroundColor: theme.color.olive,

    "& .MuiListItemText-primary": {
      color: theme.color.white,
      fontWeight: 500,
      fontSize: "1rem",
      textTransform: "capitalize",
      marginLeft: "5px",
    },

    "& .MuiListItem-root": {
      paddingLeft: "16px",
      paddingRight: "12px",
    },

    "& .MuiIconButton-root": {
      color: theme.color.white,
    },

    "& .MuiIconButton-label": {
      "& svg": {
        fontSize: "1rem",
        fontWeight: 500,
      },
    },
  },
  loginButton: {
    backgroundColor: theme.color.white,
    width: "100px",
    height: "36px",

    "& .MuiButton-label": {
      color: theme.color.orange,
      fontWeight: 500,
      fontSize: "16px",
    },
  },
  avatar: {
    background: theme.color.white,
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: "#4551B9",

    "& .MuiSvgIcon-root": {
      color: theme.color.olive,
    },
  },
  footerListItem: {
    marginLeft: "10px",
    "& .MuiListItemText-primary": {
      fontSize: "0.875rem",
    },
  },
  message: {
    textAlign: "center",
    fontSize: "1.25rem",
    margin: "25px 15px",
  },
  email: {
    color: theme.color.blue,
  },
  banner: {
    marginTop: "auto",
  },
  bannerLink: {
    height: "15vh",
    "& div": {
      width: "80vw",
    },
  },
  linkListItem: {
    "& .MuiListItem-root": {
      paddingLeft: "16px",
      paddingRight: "12px",
    },
    "& .MuiListItemText-primary": {
      fontSize: "0.875rem",
      fontWeight: "500",
    },
    "& .MuiIconButton-root": {
      color: theme.color.lightGrey,
    },
    "& .MuiIconButton-label": {
      "& svg": {
        fontSize: "1rem",
        fontWeight: 500,
      },
    },
  },
  fnpCashDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  walletIcon: {
    color: theme.color.olive,
  },
  fnpCashSpan: {
    display: "flex",
    gap: "6px",
    fontSize: "0.875rem",
    fontWeight: "500",
    margin: "4.5px 2px",
  },
  hideMenu: {
    display: "none",
  },
  fnpCashTextStyle: {
    color: theme.color.darkBlack,
  },
}));

/**
 * Left Navigation Menu
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.open boolean value to show left navigation menu or not
 * @param {Function} root0.handleClose handle drawer close
 * @returns {React.ReactElement} - returns JSX for Left Navigation Menu
 */
const LeftNavDrawer = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const footerMenu = useSelector((state) => state.appConfigs.configData.mobile.footerMenu, shallowEqual);
  const isLoggedIn = useSelector((state) => state.userDetails.checkLoginInfo?.loggedin);
  const userName = useSelector((state) => state.userDetails.name);
  const showMediaPopup = useSelector((state) => state.commonPageData.showMediaPopup);
  const menuHTML = useSelector((state) => state.commonPageData.menu) || "";
  const bannerLink = useSelector((state) => state.commonPageData.bannerLink);
  const bannerImage = useSelector((state) => state.commonPageData.bannerImage);
  const mediaEmail = useSelector((state) => state.commonPageData.mediaEmail);

  const menuHTMLRef = useRef(HTMLReactParser(menuHTML));
  const [loginURL, setLoginURL] = useState("/account/my-account");
  const [showMenuContent, setShowMenuContent] = useState(false);

  /**
   * List Header Button JSX
   *
   * @returns {React.ReactElement} - returns JSX for List Header Button
   */
  const listHeaderButton = () => {
    return isLoggedIn ? (
      <IconButton disableRipple size="small">
        <ArrowForwardIosIcon />
      </IconButton>
    ) : (
      <Button
        disableElevation
        disableRipple
        id="login"
        variant="contained"
        component="a"
        href={loginURL}
        className={classes.loginButton}
      >
        Login
      </Button>
    );
  };

  /**
   * List Footer Sub Header JSX
   *
   * @returns {React.ReactElement} - returns JSX for List Footer Sub Header
   */
  const listFooterSubHeader = () => (
    <ListSubheader component="div" id="enquiries">
      ENQUIRIES
    </ListSubheader>
  );

  /**
   * Link List Item JSX
   *
   * @param {string} primaryText text to be shown
   * @param {string} linkURL link rediection
   * @returns {React.ReactElement} - returns JSX for List Footer Sub Header
   */
  const linkListItem = (primaryText, linkURL) => (
    <ListItem disableGutters component="a" href={linkURL}>
      <ListItemText primary={primaryText} />
    </ListItem>
  );

  /**
   * Handle click event on list footer items
   *
   * @param {object} event event object
   */
  const handleClick = (event) => {
    if (event.target.innerText === "Media") {
      event.preventDefault();
      dispatch(showMediaInfo(true));
    } else if (isLoggedIn && event.currentTarget.id === "user-profile") {
      window.location.href = "/account/profile-edit";
    }
  };

  /**
   * Handle click event on list FNP cash flow control
   *
   * @param {object} event event object
   */
  const handleLabelClick = (event) => {
    if (event.currentTarget.id === "FNP-CASH") {
      window.location.href = "/account/wallet-details";
    }
  };

  useEffect(() => {
    if (!menuHTMLRef.current) {
      menuHTMLRef.current = HTMLReactParser(menuHTML);
    }
  }, [menuHTML]);

  useEffect(() => {
    if (isLoggedIn) {
      setLoginURL("/account/my-account");
    } else {
      setLoginURL(`/account/my-account?from=${window?.location.pathname}${window?.location.search}`);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (menuHTML.length && open) {
      let cssLoad = false;
      let scriptLoad = false;

      // Load Nav CSS
      const cssFile = document.createElement("link");
      cssFile.href = "/assets/css/custom/leftNavbar.css";
      cssFile.rel = "stylesheet";
      document.head.appendChild(cssFile);
      cssFile.onload = () => {
        cssLoad = true;

        if (cssLoad && scriptLoad) {
          setShowMenuContent(true);
        }
      };

      // Load Nav Script
      const script = document.createElement("script");
      script.src = "/assets/js/custom/leftNavbar.js";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        scriptLoad = true;

        if (cssLoad && scriptLoad) {
          setShowMenuContent(true);
        }
      };
    }
  }, [menuHTML.length, open]);

  if (!open) {
    return <div className={classes.hideMenu}>{menuHTMLRef.current}</div>;
  }

  return (
    <>
      <SwipeableDrawer
        disableBackdropTransition
        disableDiscovery
        disableSwipeToOpen
        anchor="left"
        open={open}
        onOpen={() => {}}
        onClose={handleClose}
      >
        <div className={classes.list}>
          <List className={classes.listHeader}>
            <ListItem id="user-profile" onClick={handleClick}>
              <ListItemIcon>
                <Avatar className={classes.avatar}>{isLoggedIn ? userName[0] : <AccountCircleIcon />}</Avatar>
              </ListItemIcon>
              <ListItemText primary={isLoggedIn ? userName : "Hi Guest"} />
              {listHeaderButton()}
            </ListItem>
          </List>
          <Divider />
          <List disablePadding className={classes.linkListItem}>
            <ListItem id="FNP-CASH" onClick={handleLabelClick}>
              <div className={classes.fnpCashDiv}>
                <span className={classes.walletIcon}>
                  <AccountBalanceWalletIcon />
                </span>
                <span className={classes.fnpCashSpan}>
                  <span className={classes.fnpCashTextStyle}>
                    <FNPCashText />
                  </span>
                  {isLoggedIn ? <FNPCashData style={{ paddingRight: "10px" }} /> : ""}
                  <NewTextBadge />
                </span>
              </div>
            </ListItem>
          </List>
          <Divider />
          <div className={showMenuContent ? "" : classes.hideMenu}>{menuHTMLRef.current}</div>
          <Divider />
          <List disablePadding className={classes.linkListItem}>
            {linkListItem("My Account", "/account/my-account")}
            {linkListItem("Contact Us", "/info/contact-us")}
          </List>
          <Divider />
          <List subheader={listFooterSubHeader()}>
            {footerMenu.map((item) => (
              <ListItem
                button
                disableRipple
                component="a"
                href={item.href}
                key={item.name}
                onClick={handleClick}
                className={classes.footerListItem}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          {bannerImage && (
            <List disablePadding className={classes.banner}>
              <ListItem component="a" href={bannerLink} className={classes.bannerLink}>
                <Image unoptimized priority src={bannerImage} alt="FNP App" layout="fill" />
              </ListItem>
            </List>
          )}
        </div>
      </SwipeableDrawer>
      <BottomAnchoredDrawer open={showMediaPopup} onClose={() => dispatch(showMediaInfo(false))}>
        <div className={classes.message}>
          Please mail to&nbsp;
          <br />
          <a href={`mailto:${mediaEmail}`} className={classes.email}>
            {mediaEmail}
          </a>
          &nbsp;for media queries
        </div>
      </BottomAnchoredDrawer>
    </>
  );
};

LeftNavDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

LeftNavDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default LeftNavDrawer;
